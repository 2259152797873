


















































































































































import api from "@/api";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  AttachmentDto,
  ServiceRecordDto,
} from "@/api/appService";

import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditBlock, { AuditApi } from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "serviceRecordDtoDetail",
  components: { AuditBlock, AttachmentsView },
  filters: {},
})
export default class ServiceRecordDetail extends Vue {
  dataId?: number;
  detail: ServiceRecordDto = {
    service: {
      title: undefined,
      type: {
        displayName: undefined,
      },
      serviceObjectType: {
        displayName: undefined,
      },
      serviceOrganization: {
        name: undefined,
      },
      serviceObjectNames: undefined,
      serviceStartTime: undefined,
      serviceEndTime: undefined,
      province: {
        provinceName: undefined,
      },
      city: {
        cityName: undefined,
      },
      area: {
        areaName: undefined,
      },
      address: undefined,
      host: undefined,
    },
    volunteer: {
      surName: undefined,
      volunteerOrgCode: undefined,
      phoneNumber: undefined,
      level: undefined,
      serviceCount: undefined,
    },
  };
  baseURL: any;
  loading = true;
  attachments: any = [];
  volunteerLevelList: any = [];
  hostType = AttachmentHostType.ServiceRecord;

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await this.fetchDetail();
      await this.loadAttachments();
      await this.getVolunteerLevelList();
    }
    this.loading = false;
  }
  // 获取详情
  async fetchDetail() {
    await api.serviceRecord
      .get({ id: this.dataId })
      .then((res: ServiceRecordDto) => {
        this.detail = { ...res };

        console.log(res);
      });
    console.log(this.detail);
  }

  async loadAttachments() {
    this.loading = false;
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.ServiceRecord,
      })
      .then((res) => {
        this.loading = true;
        this.attachments = res;
      });
    console.log(this.attachments);
  }

  async getVolunteerLevelList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "VolunteerLevel",
      })
      .then((res) => {
        this.volunteerLevelList = res.items!;
      });
  }

  getVolunteerLevel(value: any) {
    for (let i = 0; i < this.volunteerLevelList.length; i++) {
      if (this.volunteerLevelList[i].displayOrder == value) {
        return this.volunteerLevelList[i].displayName;
      }
    }
  }

  getTime(item: any) {
      return moment(item).format("HH:mm");
    }

  handleDownload(item: AttachmentDto) {
    if (this.isImage(item)) {
      window.open(item.url, "_blank");
    } else {
      const downloadUrl = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
      window.open(downloadUrl, "_blank");
    }
  }

  isImage(item: AttachmentDto) {
    if (!item.extension) {
      return false;
    }
    let ext = "";
    if (item.extension.substr(0, 1) === ".") ext = item.extension.substr(1);

    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase());
  }

  get auditApi() {
    return {
      canAudit: api.serviceRecord.canAudit,
      userAudit: api.serviceRecord.audit,
    } as AuditApi;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
